function About() {
  return (
    <div className="about" id="about">
      <p>
        Formerly a Clinical Nutritionist, I transitioned to software
        development, specializing in the MERN stack. I excel in designing,
        developing, and maintaining robust web applications with a focus on user
        experience and accessibility. Leveraging analytical skills, I craft
        visually appealing user interfaces using design tools like Figma, and
        possess a basic understanding of Blender, Affinity, and DaVinci Resolve.
        Passionate about creating seamless digital experiences, I thrive on
        problem-solving and continuously expand my skills in frontend
        development with React and backend systems with Node.js.
      </p>
    </div>
  );
}

export default About;
