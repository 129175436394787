import Name from './Name';
import Profile from './Profile';

function Home() {
  return (
    <div className="app">
      <Name />
      <Profile />
    </div>
  );
}

export default Home;
