import About from './About.js';
import Experiences from './Experiences.js';
import Projects from './Projects.js';
import Tech from './Tech.js';

function Profile() {
  return (
    <div className="profile">
      <About />
      <Projects />
      <Experiences />
      <Tech />
    </div>
  );
}

export default Profile;
