import SkillList from './SkillList.js';

function Project({ name, desc, img, skills, link }) {
  return (
    <>
      <div className="project">
        <img src={img} alt={name} />

        <h4>
          <a
            className="anchor"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </h4>
        <p>{desc}</p>
        <SkillList skills={skills} />
      </div>
    </>
  );
}

export default Project;
