import { useState } from 'react';
import SkillList from './SkillList.js';

const skills = [
  // 'Node JS',
  // 'Express',
  // 'MongoDB',
  // 'React',
  // 'Redux',
  // 'ReactRouter',
  // 'RTK',
  // 'ReactQuery',
  // 'Supabase',
  // 'Nextjs',
  // 'SSR',
  // 'Postman',
  // 'Java Script',
  // 'HTML',
  // 'CSS',
  // 'SCSS',
  // 'Tailwind',
  // 'Shopify',
  // 'Figma',
  // 'Wireframe',
  // 'Data Modelling',
  // 'API',
  // 'My SQL',
  // 'Wix',
  // 'Wordpress',
  // 'Webflow',
  // 'Jira',
  // 'MS 365 Administration',
  // 'AI Solutions',
  // 'Affinity Suite',
  // 'Davinci Resolve',
  // 'Blender',
  'Node JS',
  'Express',
  'MongoDB',
  'React',
  'Redux',
  'ReactRouter',
  'RTK',
  'ReactQuery',
  'Supabase',
  'Nextjs',
  'Java Script',
  'SCSS',
  'Tailwind',
  'Shopify',
  'Figma',
  'My SQL',
  'Wix',
  'Wordpress',
  'Webflow',
  'GraphicDesign',
];

function Name() {
  const [activeTab, setActiveTab] = useState('about');

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update active tab state when a tab is clicked
  };

  return (
    <div className="name">
      <div className="info">
        <h1>Sowmya S</h1>
        <h2>Full-Stack Developer</h2>
        <p>
          I build pixel-perfect, engaging, and accessible digital experiences.
        </p>
      </div>

      <div className="menu">
        <a
          className={`anchor ${activeTab === 'about' ? 'active' : ''}`}
          href="#about"
          onClick={() => handleTabClick('about')}
        >
          About
        </a>
        <a
          className={`anchor ${activeTab === 'projects' ? 'active' : ''}`}
          href="#projects"
          onClick={() => handleTabClick('projects')}
        >
          Projects
        </a>
        <a
          className={`anchor ${activeTab === 'experiences' ? 'active' : ''}`}
          href="#experiences"
          onClick={() => handleTabClick('experiences')}
        >
          Experience
        </a>
      </div>

      <div className="techSkills">
        <SkillList skills={skills} />
      </div>
    </div>
  );
}

export default Name;
