import Expereince from './Expereince.js';

const experiences = [
  {
    year: '2020-2024',
    company: 'Codot Studios',
    role: 'Designer | Developer | AI Solutions Specialist',
    desc: 'I design and develop user-friendly websites using Shopify, WordPress, Wix, and Webflow. With expertise in the MERN stack, I ensure seamless functionality and optimal user experience in both front-end and back-end development. I leverage AI tools for innovative solutions in data analysis, automation, image generation, UI/UX design, coding, and wireframing. My collaborative approach with clients ensures customized solutions that meet or exceed expectations.',
    skills: [
      'React JS',
      'Mongo DB',
      'Node JS',
      'Express',
      'Shopify',
      'Wix',
      'Wordpress',
      'Webflow',
      'AI Solutions',
      'Davinci Resolve',
      'Affinity Suite',
      'Blender',
      'MS 365 Administration',
      'Jira',
    ],
    link: 'https://codot.io',
  },
  {
    year: '2017-2019',
    company: 'Freelancer',
    role: 'Web Developer | Graphic Designer | Video Editor',
    desc: 'I focused on web development using Vanilla JavaScript to create interactive and responsive web experiences. Utilizing tools like Affinity Designer for graphic design and DaVinci Resolve for video editing, I crafted visually compelling content. I expanded my skills with Blender for 3D design and animation, and Adobe After Effects for motion graphics. Throughout my projects, I closely collaborated with clients to bring their visions to life, delivering high-quality results.',
    skills: [
      'HTML',
      'CSS',
      'JS',
      'Affinity Suite',
      'Davinci Resolve',
      'Adobe Illustrator',
      'Adobe Photoshop',
      'Blender',
      'Unity Engine',
    ],
    link: 'https://codot.io/',
  },
  {
    year: '2014-2016',
    company: 'Personal Projects',
    role: 'Student',
    desc: 'During an extended break period, I focused on improving my skills in various areas. I learned software testing and explored Hadoop administration. In web development, I gained expertise in HTML, CSS, and JavaScript for dynamic websites. Additionally, I studied Java, JSP, Oracle, and MySQL for backend development and database management.',
    skills: [
      'HTML',
      'CSS',
      'SCSS',
      'JavaScript',
      'Java',
      'JSP',
      'Software Testing',
      'Hadoop Administartion',
      'Virtual Machine',
      'Linux Administration',
    ],
    link: '#',
  },
  {
    year: '2012-2013',
    company: 'VLCC Health Care',
    role: 'Slimming Center Manager',
    desc: 'I oversaw the slimming and weight management department, leading a team of dietitians and experts in creating personalized plans, conducting assessments, and offering nutrition and exercise guidance. Responsibilities included staff training, developing innovative techniques, implementing marketing strategies, and ensuring customer satisfaction and business growth.',
    skills: [
      'Management',
      'Leadership',
      'Nutrition',
      'CustomerService',
      'TimeManagement',
      'Obesity',
    ],
    link: 'https://vlcc.in/',
  },
  {
    year: '2008-2011',
    company: 'Apollo Hospitals',
    role: 'Clinical Nutritionist',
    desc: 'I specialized in enteral and parenteral nutrition, medical nutritional therapy, and dietary counseling across specialties. I developed personalized nutrition plans, conducted assessments, and educated on healthy eating. This experience enhanced my skills in therapeutic nutrition and patient-centered counseling, leading to improved outcomes and well-being.',
    skills: [
      'Enteral',
      'Parenteral',
      'Critical Care',
      'Burns',
      'Liver',
      'Renal',
      'Cardiac',
      'Diabetes',
      'Obesity',
      'Diabetes',
      'Gastro Intestinal',
      'Pediatric',
      'Surgery',
      'Eating Disorders',
      'Transplant',
    ],
    link: 'https://www.apollohospitals.com/chennai/hospitals/greams-road/',
  },
];

function Experiences() {
  return (
    <div id="experiences">
      <div className="heading">Experience</div>
      {experiences.map((experience, i) => (
        <Expereince
          year={experience.year}
          company={experience.company}
          role={experience.role}
          desc={experience.desc}
          skills={experience.skills}
          link={experience.link}
          key={i}
        />
      ))}
    </div>
  );
}

export default Experiences;
