function Tech() {
  return (
    <div>
      <p>
        Designed in Figma and coded in Visual Studio Code. Built with React.js.
      </p>
    </div>
  );
}

export default Tech;
