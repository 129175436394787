import Project from './Project.js';

const projects = [
  {
    name: 'Pinecrest Paradise - Hotel Booking App (Staff)',
    desc: 'Pinecrest Paradise is a hotel booking app with advanced functionalities developed using React, Supabase, ReactRouter, ReactQuery, and Context API.',
    img: './images/pinecrestparadise.png',
    skills: ['React', 'Supabase', 'ReactRouter', 'ReactQuery', 'Context API'],
    link: 'https://pinecrest-paradise.netlify.app',
  },
  // {
  //   name: 'Pinecrest Paradise - Hotel Booking App (Customer)',
  //   desc: 'Pinecrest Paradise is a hotel booking app with advanced functionalities developed using Next.js, offering a seamless and efficient user experience for customers.',
  //   img: './images/pinecrestparadise.png',
  //   skills: ['Next.js'],
  //   link: 'https://pinecrest-paradise.netlify.app',
  // },
  {
    name: 'PizzaPalette - Online Pizza Ordering App',
    desc: `PizzaPalette is a modern online pizza ordering app built using Tailwind CSS, React, Redux, and ReactRouter. `,
    img: './images/pizzapalette.png',
    skills: ['Tailwind', 'React', 'Redux', 'ReactRouter'],
    link: 'https://pizzapalette.netlify.app/',
  },
  {
    name: 'HummingHaus - E-commerce Website',
    desc: `An e-commerce website for a rug and carpet store using Shopify. The store had a specific vision for a Scandinavian theme, and I was able to bring it to life through custom design and development. The result was a functional online store that perfectly captured the store's brand and vision.`,
    img: './images/shopify.png',
    skills: ['Shopify', 'JS', 'Liquid'],
    link: 'https://www.humminghausrugs.com/',
  },
  {
    name: 'TBC - Content Management',
    desc: `Managed content creation for product packaging, overseeing the development of concise and persuasive descriptions for a range of skincare products. Collaborated with content writers to ensure descriptions highlighted key benefits and usage instructions, contributing to effective packaging communication strategies.`,
    img: './images/tbc.png',
    skills: ['Copywriting', 'Product Description Writing', 'Content Strategy'],
    link: 'https://theboo.store/',
  },
  {
    name: 'TrekTrails - A trek booking Web App',
    desc: `A trek booking Node.js application powered by MongoDB, Express, Pug, and Stripe for secure payments. Seamlessly combining thesetechnologies, users can browse, select, and book their preferred treks. With a user-friendly interface and reliable data management, this platform ensures a smooth and secure trek booking experience for adventure enthusiasts.`,
    img: './images/tt.png',
    skills: ['Node JS', 'Express', 'PUG', 'Mongo DB'],
    link: 'https://trektrails.vercel.app/',
  },
  {
    name: 'Forkify - Web App',
    desc: `A web application designed to provide a user-friendly experience for discovering recipes. Developed using HTML, CSS, and JavaScript, this platform fetches recipe data from an API based on search queries. Explore a variety of dishes efficiently and effortlessly through this functional and responsive web app.`,
    img: './images/forkify.png',
    skills: ['HTML', 'CSS', 'JS', 'API'],
    link: 'https://forkify-ssowm.netlify.app/',
  },
  {
    name: 'Mapty - JS Application',
    desc: `A fitness tracking web application meticulously built for personalized health monitoring. Developed exclusively with HTML, CSS, and JavaScript, this platform utilizes local storage to securely store user data. Track your progress with ease, from workouts to daily calorie intake, through a user-friendly interface designed for efficient fitness management.`,
    img: './images/mapty.png',
    skills: ['HTML', 'CSS', 'JS'],
    link: 'https://mapty-sowm.netlify.app/',
  },
  {
    name: 'Bankist - JS Application',
    desc: `A banking web application developed for efficient financial management. Constructed using HTML, CSS, and JavaScript, this platform interfaces securely with banking APIs to deliver a suite of essential features. From comprehensive account insights to seamless fund transfers, experience a user-centric interface tailored for streamlined banking operations.`,
    img: './images/bankist.png',
    skills: ['React JS'],
    link: 'https://bankist-sowm.netlify.app/',
  },
  {
    name: 'UsePopcorn - React Application',
    desc: `A movie tracker that fetches film data from the omdb API. Easily log the movies you've watched and rate them.`,
    img: './images/usePopcorn.png',
    skills: ['React JS'],
    link: 'https://use-popcorn-sowm.netlify.app/',
  },
  {
    name: 'FarFarAway - React Application',
    desc: `A handy packing list to keep your travel essentials in check. Easily add and manage items for your trips.`,
    img: './images/farfaraway.png',
    skills: ['React JS'],
    link: 'https://react-far-away-sowm.netlify.app/',
  },
  // {
  //   name: 'Pizza Menu, Split & eat - React Application',
  //   desc: `A delightful pizza menu display and an intuitive tool to calculate bills and split costs among friends. Simply input expenses, split the bill, and track who owes what. Easily manage shared expenses and keep track of balances in one convenient place.`,
  //   img: './images/pizza.png',
  //   skills: ['React JS'],
  //   link: 'https://react-pizza-sowm.netlify.app/',
  // },
];

function Projects() {
  return (
    <div id="projects">
      <div className="heading">Projects</div>
      {projects.map((project, i) => (
        <Project
          name={project.name}
          desc={project.desc}
          img={project.img}
          skills={project.skills}
          link={project.link}
          key={i}
        />
      ))}
    </div>
  );
}

export default Projects;
