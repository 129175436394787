import SkillList from './SkillList.js';

function Expereince({ year, company, role, desc, skills, link }) {
  return (
    <>
      <div className="experience">
        <div className="year">{year}</div>

        <h4>
          <a
            className="anchor"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {company}
          </a>
        </h4>
        <div className="role">{role}</div>
        <p>{desc}</p>
        <SkillList skills={skills} />
      </div>
    </>
  );
}

export default Expereince;
